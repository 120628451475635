import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CourseCardListItem from './course-card-list-item';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}));

export default function CourseCardList({ courses, onClick, ...props }) {

    return (
        <Grid container spacing={2}>
            {courses.map(course =>
                <CourseCardListItem {...props} key={course.target} course={course.target} onClick={onClick} />)}
        </Grid>
    )

}
