import React, { useCallback } from 'react';
import { Drawer, ListItem, ListItemIcon, SvgIcon, ListItemText, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import ProgrammeTitle from '../element/programme-title';
import ProgrammeDescription from '../element/programme-description';
import CourseList from '../../../course/component/list/course-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'
import { ArrowLeft } from '@material-ui/icons'
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const useStyles = makeStyles(theme => ({
    drawer: {
        position: 'absolute',
        left: 0,
        width: theme.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: theme.drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    dense: {
        minWidth: theme.spacing(5)
    }
}));

function ProgrammeSidebar({ programme, course, onClick, ...props }) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const goHome = useCallback((to, ...args) => {
        dispatch(push(`/app/`, ...args));
    }, []);

    return (
        <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
            <div className={classes.toolbar} />
            <ListItem divider button onClick={goHome}>
                <ListItemIcon className={classes.dense}><ArrowLeft /></ListItemIcon>
                <ListItemText primary={<Typography>Back to Home</Typography>} />
            </ListItem>
            <ListItem button onClick={() => onClick('')}>
                <ListItemIcon className={classes.dense}><SvgIcon><FontAwesomeIcon icon={faPuzzlePiece} /></SvgIcon></ListItemIcon>
                <ListItemText
                    primary={<ProgrammeTitle programme={programme} />}
                    secondary={<ProgrammeDescription component='div' noWrap programme={programme} />}
                />
            </ListItem>
            <CourseList programme={programme} course={course} onClick={onClick} />
        </Drawer>
    );
}

export default ProgrammeSidebar;
