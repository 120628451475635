import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

function Title({ programme, ...props }) {
    return (
        <Typography {...props} >
            {programme.payload.title}
        </Typography>
    )
}

function ProgrammeTitle({ programme, ...props }) {
    return (
        <ResourceGetter resource='programmes' id={programme} render={({ programme }) =>
            <WaitingLoaded data={programme} render={() => <Title {...props} programme={programme} />} />
        } />
    )
}

export default ProgrammeTitle;
