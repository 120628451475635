import React from 'react';
import classnames from 'classnames';
import { CardMedia, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[700],
        minHeight: 80,
        position: 'relative',
    }
}));

function BCardMedia({ background, title, className, children, ...props }) {

    const classes = useStyles();

    return (
        <CardMedia className={classnames(classes.root, className)} {...props} image={background} title={title}>
            {children}
        </CardMedia>
    )
}

export default BCardMedia;
