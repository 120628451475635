import React, { useCallback } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import ContentView from './content-view';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ResourceGetter } from '../../../lib/training-sdk/getter';
import BreadCrumb from '../../common/component/breadcrumbs/breadcrumb';


function ContentLayout({ programme, course, ...props }) {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    const { content: id } = useParams();

    const dispatch = useDispatch();

    const onClick = useCallback((to, ...args) => {
        dispatch(push(`${url}/${to}`, ...args));
    }, [url]);

    return (
        <React.Fragment>
            <ResourceGetter resource='contents' id={id} query={{ programme, course }} render={({ content }) =>
                <BreadCrumb handle={id} text={content && content.payload && content.payload.title} link={url} />
            } />
            <ContentView {...props} content={id} programme={programme} course={course} onClick={onClick} url={url} />
        </React.Fragment>
    )

}

export default ContentLayout;
