import React from 'react';
import { Grid } from '@material-ui/core';
import ProgrammeCardListItem from './programme-card-list-item';

export default function ProgrammeCardList({ programmes, onClick, ...props }) {
    return (
        <Grid container spacing={2}>
            {programmes.map(programme =>
                <ProgrammeCardListItem {...props} onClick={onClick}
                    key={programme.target} programme={programme.target}
                />)}
        </Grid>
    );
}
