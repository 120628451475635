import React, { useMemo, createContext, useContext } from 'react';
import { useSnackbar, SnackbarProvider } from 'notistack';
import _ from 'lodash';

const notifContext = createContext();

const NotificationControler = ({ children }) => {

    const sb = useSnackbar();
    const notifCtx = useMemo(() => ({
        success: (message, o) => sb.enqueueSnackbar(message, { ...o, variant: 'success', preventDuplicate: true, }),
        info: (message, o) => sb.enqueueSnackbar(message, { ...o, variant: 'info', preventDuplicate: true, persist: true }),
        warning: (message, o) => sb.enqueueSnackbar(message, { ...o, variant: 'warning', preventDuplicate: true, }),
        error: (e, o) => {
            const message = _.get(e, 'response.data.message') || _.get(e, 'message') || e;
            sb.enqueueSnackbar(message, { ...o, variant: 'error', preventDuplicate: true, });
        },
        close: (key) => sb.closeSnackbar(key),
    }), [sb]);

    return (
        <notifContext.Provider value={notifCtx}>
            {children}
        </notifContext.Provider>
    )
}

const NotificationProvider = (props) => {

    return (
        <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <NotificationControler {...props} />
        </SnackbarProvider>
    )

}

export const useNotif = () => useContext(notifContext);

export default NotificationProvider;