import React, { useEffect, useMemo, createContext, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { flagApplicationStarts } from '../action';
import { makeApplicationStateSelector } from '../selector';

import ApplicationStartingScreen from './application-starting';
import ApplicationStartedScreen from './application-started';
import ApplicationStoppingScreen from './application-stopping';
import ApplicationStoppedScreen from './application-stopped';
import ApplicationLoadingBar from './application-loading-bar';
import Header from './app-bar-header';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: '100%',
    }
}));


const appContext = createContext();
export const useApp = () => useContext(appContext);

const App = (props) => {

    const classes = useStyles();

    const applicationStateSelector = useMemo(() => makeApplicationStateSelector(), []);

    const dispatch = useDispatch();
    const application = useSelector(applicationStateSelector);

    useEffect(() => {
        if (application === 'stopped') {
            dispatch(flagApplicationStarts());
        }
    }, []);

    const title = process.env.REACT_APP_TITLE;

    const children = useMemo(() => {
        switch (application) {
            case 'starting':
                return <ApplicationStartingScreen />;
            case 'started':
                return <ApplicationStartedScreen />;
            case 'stopping':
                return <ApplicationStoppingScreen />;
            case 'stopped':
            case 'erred':
                return <ApplicationStoppedScreen />;
            default:
                return null;
        }
    }, [application]);

    return (
        <div className={classes.root}>
            <ApplicationLoadingBar />
            <Header title={title} />
            {children}
        </div>
    )

}

export default App;