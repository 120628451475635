import React from 'react';
import { Button } from '@material-ui/core';

const ActionOk = ({ notif, handle }) => {

    return (
        <Button onClick={() => { notif.close(handle) }}>
            Ok
        </Button>
    );
}

export default ActionOk;
