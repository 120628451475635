import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Skeleton } from '@material-ui/lab';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';
import { ListItemAvatar, Avatar, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import CourseImage from '../element/course-image';
import { makeStyles } from '@material-ui/styles';
import { ArrowRight } from '@material-ui/icons';
import CourseTitle from '../element/course-title';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.primary[theme.palette.type],
    },
}));

function CourseListItemLoading({ setindex, course, ...props }) {
    return (
        <ListItem {...props}>
            <ListItemAvatar >
                <Skeleton />
            </ListItemAvatar>
            <ListItemText primary={<Skeleton />} />
            <ListItemSecondaryAction>
                <IconButton edge='end'>
                    <Skeleton variant='circle' />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

function CourseListItemView({ course, programme, onClick, ...props }) {

    return (
        <ListItem onClick={onClick} {...props}>
            <ListItemText primary={<CourseTitle noWrap course={course.target} programme={programme} />} />
            <ListItemSecondaryAction>
                <IconButton onClick={onClick} edge='end'>
                    <ArrowRight />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default function CourseListItem({ programme, course: id, onClick, ...props }) {
    return (
        <ResourceGetter resource='courses' id={id} query={{ programme }} render={({ course }) => {
            const isLoading = !(course && course.payload);
            if (isLoading) return <CourseListItemLoading {...props} />
            return <CourseListItemView {...props} button onClick={() => onClick(`${id}`)} course={course} />
        }} />
    );
}
