import React from 'react';

import { Typography, Button } from '@material-ui/core';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
    },
}));


const ApplicationStoppedScreen = (props) => {
    const classes = useStyles();

    return (
        <CenteredFullScreen>
            <Typography component='h3'>Application is stopped, please refresh your browser</Typography>
            <Button className={classes.button} color='primary' onClick={() => window.location.reload()}>Refresh</Button>
        </CenteredFullScreen>
    );
}

export default ApplicationStoppedScreen;
