import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import ContentViewer from '../element/content-viewer';
import { Paper } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        backgroundColor: theme.palette.background.paper,
    },
}));

function ContentCarousel({ content, contents, course, programme, onClick, previousCourse, nextCourse }) {
    const classes = useStyles();

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(-1);
    const maxSteps = contents.length;

    useEffect(() => {
        setActiveStep(contents.indexOf(content));
    }, [contents, content]);

    const handleNext = () => {
        if (activeStep === maxSteps - 1) {
            if (nextCourse) nextCourse();
        } else {
            onClick(contents[activeStep + 1]);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            if (previousCourse) previousCourse();
        } else {
            onClick(contents[activeStep - 1]);
        }
    };

    const handleStepChange = (step) => {
        onClick(contents[step]);
    };

    return (
        <Paper className={classes.root} elevation={16}>
            {maxSteps > 1 && <MobileStepper
                className={classes.header} steps={maxSteps} position="static" variant="text" activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1 && !nextCourse}>
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0 && !previousCourse}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Previous
                    </Button>
                }
            />}
            {activeStep >= 0 && (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    animateHeight
                >
                    {contents.map((content, index) => (
                        <ContentViewer key={content} content={content} course={course} programme={programme} />
                    ))}
                </SwipeableViews>
            )}
        </Paper>
    );
}

export default ContentCarousel;
