import { createSelector } from 'reselect';
import _ from 'lodash';

export function makeListProgrammes(sdk) {
    return createSelector(
        sdk.programmes.selectors.getDb,
        (db) => {
            return _.chain(db).values()
                .filter(p => p && p.payload)
                .sortBy('payload.index').value();
        }
    );
}

