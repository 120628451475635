import _ from 'lodash';
import {
    APPLICATION_STARTING, APPLICATION_STARTED, APPLICATION_STOPPING, APPLICATION_STOPPED, APPLICATION_ERRED, APPLICATION_RESET_LOADER,
} from './action';
import { combineReducers } from 'redux';


function main(state = 'stopped', { type, payload, meta }) {

    switch (type) {
        case APPLICATION_STARTING:
            return 'starting';
        case APPLICATION_STARTED:
            return 'started';
        case APPLICATION_STOPPING:
            return 'stopping';
        case APPLICATION_STOPPED:
            return 'stopped';
        case APPLICATION_ERRED:
            return 'erred';
        default:
            return state;
    }

}

function loader(state = { pending: 0, finished: 0, erred: 0 }, { type }) {
    state = _.cloneDeep(state);

    if (type.endsWith('/pending')) {
        state.pending++;
    }
    if (type.endsWith('/error')) {
        state.erred++;
    }
    if (type.endsWith('/success')) {
        state.finished++;
    }

    switch (type) {
        case APPLICATION_RESET_LOADER:
            state = { pending: 0, finished: 0, erred: 0 }
            break;
        default:
            break;
    }

    return state;
}

export default combineReducers({
    main,
    loader,
});
