import { combineReducers } from 'redux';
import Resource, { SERVER_NETWORK_ERROR, INTERNAL_SERVER_ERROR } from './resource';
import Actions from './actions';
import Reducer from './reducers';
import HelpersBuilder from './helpers.js';

export * from './context';

export { SERVER_NETWORK_ERROR, INTERNAL_SERVER_ERROR };
export const ACTIONS = {
    programmes: new Actions('programmes'),
    courses: new Actions('courses'),
    contents: new Actions('contents'),
}

export const reducers = combineReducers({
    programmes: Reducer('programmes'),
    courses: Reducer('courses'),
    contents: Reducer('contents'),
});

export default class SDK {

    constructor(uri, pathInState) {
        this.programmes = new Resource('programmes', uri, pathInState);
        this.courses = new Resource('courses', uri, pathInState);
        this.contents = new Resource('contents', uri, pathInState);
        
        this.helpers = HelpersBuilder(this);
    }

    actions() {
        return {
            SERVER_NETWORK_ERROR,
            INTERNAL_SERVER_ERROR,
        }
    }

}
