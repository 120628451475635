import React, { useEffect } from 'react';

import Loader from '../../common/component/loader';
import { useDispatch } from 'react-redux';
import { flagApplicationHasStarted } from '../action';
import { useAuth0 } from '../../../lib/auth0-wrapper';


const ApplicationStartingScreen = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(flagApplicationHasStarted());
    }, []);

    return (
        <Loader message={'Wait while application is loading ... '} />
    );
}

export default ApplicationStartingScreen;
