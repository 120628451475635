import React, { useEffect } from 'react';

import Loader from '../../common/component/loader';
import { useDispatch } from 'react-redux';
import { flagWorkbenchHasStarted } from '../action';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { useSdk } from '../../../lib/training-sdk/context';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useNotif } from '../../common/component/notification';
import ActionOk from '../../common/component/notification/action-ok';

const WorkbenchStartingScreen = (props) => {

    const dispatch = useDispatch();
    const sdk = useSdk();
    const auth = useAuth0();
    const notif = useNotif();

    useEffect(() => {
        Promise.all([
            dispatch(sdk.programmes.list(auth)),
            dispatch(sdk.courses.list(auth)).then(courses => {
                if (courses) {
                    return Promise.all(courses.map(course => dispatch(sdk.courses.get(auth, course, {}))))
                } else {
                    return Promise.resolve();
                }
            }),
        ])
            .then(() => dispatch(flagWorkbenchHasStarted()))
            .catch(e => notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> }));

    }, []);

    return (
        <CenteredFullScreen>
            <Loader message={'Wait while the console is starting ... '} />
        </CenteredFullScreen>
    );
}

export default WorkbenchStartingScreen;
