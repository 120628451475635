import React, { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';
import { makeStyles } from '@material-ui/styles';
import { CardActions, Button, Grid, CardActionArea, CardContent, Card, Chip } from '@material-ui/core';
import ProgrammeMedia from '../card/programme-card-media';
import ProgrammeTitle from '../element/programme-title';
import ProgrammeDescription from '../element/programme-description';

const useStyles = makeStyles((theme) => ({
    area: {
        position: 'relative',
    },
    button: {
        marginLeft: 'auto',
    },
    description: {
        display: '-webkit-box',
        height: 40,
        '-webkit-line-clamp': 2,
        overflow: 'hidden',
        '-webkit-box-orient': 'vertical',
    },
    chipBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 90%, rgba(0,0,0,0.0) 100%);'
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        padding: theme.spacing(1),
    }
}));

export function ProgrammeCardListItemLoading({ setindex, course, ...props }) {
    return (
        <Grid item xs={12} sm={6} md={3} {...props}>
            <Skeleton width='100%' heigth='auto' />
        </Grid>
    )
}

function ProgrammeCardListItemView({ programme, ...props }) {

    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} md={3} {...props}>
            <Card elevation={4}>
                <CardActionArea className={classes.area}>
                    <ProgrammeMedia programme={programme.target} />
                    <CardContent>
                        <ProgrammeTitle noWrap gutterBottom variant="h5" component="h2" programme={programme.target} />
                        <ProgrammeDescription className={classes.description} variant="body2" color="textSecondary" component="p" programme={programme.target} />
                    </CardContent>
                    <Chip className={classes.chip} /*variant='outlined'*/ color='primary' label='Programme' />
                </CardActionArea>
                <CardActions >
                    <Button className={classes.button} size="small" color="primary">
                        Open
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default function ProgrammeCardListItem({ programme, onClick, ...props }) {
    return (
        <ResourceGetter resource='programmes' id={programme} render={({ programme }) => {
            const isLoading = !(programme && programme.payload);
            if (isLoading) return <ProgrammeCardListItemLoading {...props} />
            return <ProgrammeCardListItemView {...props} onClick={() => onClick(`${programme.target}`)} programme={programme} />
        }} />
    );
}
