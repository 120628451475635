import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
    },
    link: {
        position: 'relative',
        padding: 15,
        textDecoration: 'none',
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative',
        whiteSpace: 'nowrap',
        '&:hover': {
            '&:after': {
                height: 6,
            }
        },
        '&:after': {
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 0,
            transition: 'height .2s',
            content: '""',
            backgroundColor: theme.palette.common.white,
        }
    },
    text: {
        color: theme.palette.common.white,
        lineHeight: '20px',
    }
}));

export default function AppBarLink({ href, children, ...props }) {

    const classes = useStyles();

    return (
        <li className={classes.root}>
            <a className={classes.link} href={href}>
                <Typography {...props} className={classes.text} >{children}</Typography>
            </a>
        </li>
    );
}
