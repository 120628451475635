import React, { useEffect, useMemo } from 'react';
import { useBread } from './breadcrumbs-root';

export default function BreadCrumb({ handle, text, link, children }) {
    const crumbs = useBread();

    useMemo(() => {
        crumbs.push(handle, { text, link });
    }, [handle, text, link]);

    useEffect(() => {
        return () => crumbs.pop(handle);
    }, [handle]);

    return children || null;
}
