import { createSelector } from 'reselect';

export function makeWorkbenchStateSelector() {
    return createSelector(
        workbenchStateSelector,
        status => status
    );
}

export const workbenchStateSelector = state => state.workbench.status;
