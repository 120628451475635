import React, { useContext } from "react";

export const SdkContext = React.createContext();

export const useSdk = () => useContext(SdkContext);
export const withSdk = (Component) => {
    return function SdkWrapper(props) {
        return (
            <SdkContext.Consumer>
                {(sdk) => <Component {...props} sdk={sdk} />}
            </SdkContext.Consumer>
        )
    }
};

export const SdkProvider = ({ children, sdk }) => {
    return (
        <SdkContext.Provider value={sdk}>
            {children}
        </SdkContext.Provider>
    );
};
