import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useHistory } from './history-handler';

///////////////////////////////////////////
// Handler
///////////////////////////////////////////


const RouterHandler = ({ children }) => {
    const history = useHistory();

    return (
        <ConnectedRouter history={history}>
            {children}
        </ConnectedRouter>
    );

}

export default RouterHandler;
