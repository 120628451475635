import React, { useMemo, useEffect } from "react";
import { useSdk } from "./context";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../auth0-wrapper";
import ActionOk from "../../app/common/component/notification/action-ok";
import { useNotif } from "../../app/common/component/notification";

export const ResourceGetter = ({ render, component, resource, id, query, ...props }) => {

    const dispatch = useDispatch();
    const sdk = useSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const name = useMemo(() => resource.endsWith('s') ? resource.slice(0, resource.length - 1) : resource, [resource]);

    const dataSelector = useMemo(() => sdk[resource].selectors.makeGet(), [resource]);
    const data = useSelector(state => dataSelector(state, id));

    useEffect(() => {
        if (!data) {
            dispatch(sdk[resource].get(auth, id, query))
                .catch(e => {
                    notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> });
                });
        }
    }, [resource]);

    const innerProps = props;
    innerProps[name] = data;

    if (render) {
        return render(innerProps);
    } else if (component) {
        return React.createElement(component, innerProps);
    } else return null;
};
