import React, { useEffect } from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

function Title({ setindex, course, ...props }) {

    useEffect(() => {
        if (setindex)
            setindex(course.payload.index);
    }, []);

    return (
        <Typography {...props} >
            {course.payload.title}
        </Typography>
    )
}

function WaitingTitle({ course, ...props }) {
    return (
        <WaitingLoaded data={course} render={() =>
            <Title {...props} course={course} />
        } />
    )
}

function CourseTitle({ course, programme, ...props }) {
    return (
        <ResourceGetter resource='courses' id={course} query={{ programme }} render={({ course }) =>
            <WaitingTitle {...props} course={course} />
        } />
    )
}

export default CourseTitle;
