import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Switch, Route } from 'react-router-dom';
import CourseLayout from '../../course/component/course-layout';
import Loader from '../../common/component/loader';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { Typography } from '@material-ui/core';
import { useSdk } from '../../../lib/training-sdk/context';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useNotif } from '../../common/component/notification';
import ActionOk from '../../common/component/notification/action-ok';
import { makeListCourses } from '../../course/selector';
import ProgrammeCard from './card/programme-card';
import CourseCardList from '../../course/component/card-list/course-card-list';
import ProgrammeSidebar from './sidebar';
import { makeStyles } from '@material-ui/styles';
import BreadCrumbsView from '../../common/component/breadcrumbs/breadcrumbs-view';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: theme.drawerWidth,
        right: 0,
        top: 0,
    },
    content: {
        flexGrow: 1,
        width: '100%',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
}));

function LoadCourses({ programme }) {

    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    useEffect(() => {
        if (programme && programme.payload) {
            Promise.all(programme.payload.courses.map(course =>
                dispatch(sdk.courses.get(auth, course, { programme: programme.target }))
            ))
                .catch(e => notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> }));

        }
    }, [programme]);

    return null;
}

function ListAllCourses({ programme, onClick }) {

    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);

    const coursesSelector = useMemo(() => makeListCourses(sdk), []);
    const courses = useSelector(state => coursesSelector(state, programme));

    useEffect(() => {
        dispatch(sdk.courses.list(auth, { programme }))
            .then(courses => Promise.all(courses ? courses.map(c => dispatch(sdk.courses.get(auth, c, {}))) : []))
            .catch(e => notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> }))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Loader message='Loading courses' />
    } else if (courses.length === 0) {
        return (
            <CenteredFullScreen>
                <Typography>No courses available</Typography>
            </CenteredFullScreen>
        )
    } else {
        return (
            <ProgrammeCard programme={programme}>
                <CourseCardList courses={courses} programme={programme} onClick={onClick} />
            </ProgrammeCard>
        )
    }
}

function ProgrammeView({ id, programme, onClick, onBack, url, ...props }) {

    const classes = useStyles();
    const [course, setCurrentCourse] = useState(null);

    return (
        <React.Fragment>
            <ProgrammeSidebar programme={id} course={course} onClick={onClick} />
            <LoadCourses programme={programme} />
            <div className={classes.root}>
                <div className={classes.content}>
                    <BreadCrumbsView />
                    <Switch>
                        <Route path={`${url}/:course`} render={() =>
                            <CourseLayout setCurrentCourse={setCurrentCourse} programme={id} onBack={onClick} />} />
                        <Route render={() => (
                            <ListAllCourses programme={id} onClick={onClick} />
                        )} />
                    </Switch>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProgrammeView;
