import React from 'react';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';
import background from '../../../../assets/images/illustration-b@2x.png';

function Image({ course, ...props }) {
    return (
        <img {...props} width='100%' src={course.payload.image || background} alt={course.payload.title} />
    )
}

function CourseImage({ course, programme, ...props }) {
    return (
        <ResourceGetter resource='courses' id={course} query={{ programme }} render={({ course }) => {
            if (!(course && course.payload)) return null;
            return <Image {...props} course={course} />
        }} />
    )
}

export default CourseImage;
