import { createSelector } from 'reselect';

export default function Selectors(path, resource) {

    const getDb = (state) => state[path][resource].byId;
    const get = (state, id) => getDb(state)[id];
    const list = state => state[path][resource].allIds;

    const isInserted = (state) => state[path][resource].insert.status.endsWith('pending');

    const makeIsInserted = () => {
        return createSelector(
            isInserted,
            inserted => inserted
        )
    }

    const makeList = () => {
        return createSelector(
            list,
            allIds => allIds
        )
    }

    const makeListCount = () => createSelector(list, allIds => allIds.count);

    const makeListIds = () => createSelector(list, elements => elements.ids);

    const makePopulateList = () => {
        return createSelector(
            makeListIds(),
            getDb,
            (list, db) => list.map(id => db[id])
        )
    }

    const makePopulateListPayload = () => createSelector(makePopulateList(), list => list.map(el => el && el.payload))

    const makeExists = () => {
        return createSelector(
            list,
            (_, toFind) => toFind,
            (elements, toFind) => elements.ids.includes(toFind)
        )
    }

    const makeGet = () => createSelector(get, element => element)

    const makeGetPayload = () => createSelector(get, element => element && element.payload);

    const makeGetStatus = () => createSelector(get, element => element && element.status);

    const makeIsSynchronized = () => createSelector(get, element => element !== undefined);

    return {
        isInserted,
        makeIsInserted,

        list,
        makeList,
        makeListIds,
        makeListCount,
        makePopulateList,
        makePopulateListPayload,
        makeExists,

        getDb,
        get,
        makeGet,
        makeGetPayload,
        makeGetStatus,
        makeIsSynchronized,
    }

}