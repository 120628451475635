import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import ReactMarkdown from 'react-markdown/with-html';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    viewer: {
        width: '100%',
        padding: theme.spacing(4),
    }
}));

function MarkdownViewer(props) {
    const { content } = props;
    const classes = useStyles();
    return (
        <WaitingLoaded data={content} render={() => <ReactMarkdown className={classes.viewer} source={content.payload.markdown} escapeHtml={false}/>} />
    )
}

export default MarkdownViewer;
