export default class Actions {

    constructor(resource) {
        this['get'] = this._actions(resource, 'get');
        this['post'] = this._actions(resource, 'post');
        this['put'] = this._actions(resource, 'put');
        this['list'] = this._actions(resource, 'list');
        this['remove'] = this._actions(resource, 'remove');
        this['count'] = this._actions(resource, 'count');
        this['clear'] = `@${resource}/clear`;
    }

    _actions(resource, operation) {
        return {
            pending: `@${resource}/${operation}/pending`,
            success: `@${resource}/${operation}/success`,
            error: `@${resource}/${operation}/error`,
        }
    }

}