import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: 'auto',
    },
}));

const CenteredFullScreen = ({ children, textAlign = 'left', className }) => {
    const classes = useStyles();

    return (
        <div style={{textAlign}} className={classnames(classes.centered, className)}>
            {children}
        </div>
    );
}

export default CenteredFullScreen;
