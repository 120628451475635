import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { flagWorkbenchStarts } from '../action';

import WorkbenchStartingScreen from './workbench-starting';
import WorkbenchStartedScreen from './workbench-started';
import { makeWorkbenchStateSelector } from '../selector';

const Workbench = (props) => {

    const dispatch = useDispatch();

    const workbenchStateSelector = useMemo(() => makeWorkbenchStateSelector(), []);
    const workbench = useSelector(workbenchStateSelector);

    useEffect(() => {
        dispatch(flagWorkbenchStarts());
    }, []);

    switch (workbench) {
        case 'starting':
            return <WorkbenchStartingScreen />;
        case 'started':
            return <WorkbenchStartedScreen />;
        case 'stopping':
        default:
            return null;
    }
}

export default Workbench;