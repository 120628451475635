import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Grid, MuiThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSdk } from '../../../lib/training-sdk/context';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useNotif } from '../../common/component/notification';
import ActionOk from '../../common/component/notification/action-ok';
import ProgrammeCardList from '../../programme/component/card-list/programme-card-list';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import CourseCardList from '../../course/component/card-list/course-card-list';
import { themeProgrammes, themeTuto } from '../../../theme';
import { makeListStandaloneCourses } from '../../course/selector';
import { makeListProgrammes } from '../../programme/selector';
import BreadCrumb from '../../common/component/breadcrumbs/breadcrumb';
import DashboardContainer from './dashboard-container';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%',
        padding: theme.spacing(4),
    },
    toolbar: theme.mixins.toolbar,
}));

function DashboardLayoutView({ programmes, tutorials, onClick, ...props }) {

    const classes = useStyles();

    const onProgrammesClick = (to) => onClick(`programmes/${to}`);
    const onTutorialsClick = (to) => onClick(`tutorials/${to}`);

    return (
        <React.Fragment>
            <div className={classes.fullWidth} >
                <Grid container spacing={4} >
                    <Grid item xs={12}>
                        <DashboardContainer title={'Programms'}>
                            <MuiThemeProvider theme={themeProgrammes}>
                                <ProgrammeCardList {...props} xs={12} sm={6} md={3} lg={2} programmes={programmes} onClick={onProgrammesClick} />
                            </MuiThemeProvider>
                        </DashboardContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <DashboardContainer title={'Tutorials'}>
                            <MuiThemeProvider theme={themeTuto}>
                                <CourseCardList {...props} xs={12} sm={6} md={3} lg={2} courses={tutorials} onClick={onTutorialsClick} />
                            </MuiThemeProvider>
                        </DashboardContainer>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

function DashboardLayoutController(props) {

    const dispatch = useDispatch();
    const sdk = useSdk();
    const auth = useAuth0();
    const notif = useNotif();

    const [loading, setLoading] = useState(true);

    const coursesSelector = useMemo(() => makeListStandaloneCourses(sdk), []);
    const standalones = useSelector(coursesSelector);

    const programmesSelector = useMemo(() => makeListProgrammes(sdk), []);
    const programmes = useSelector(programmesSelector);

    console.log(programmes)

    useEffect(() => {
        Promise.all([
            dispatch(sdk.courses.list(auth))
                .then(courses => Promise.all(courses ? courses.map(c => dispatch(sdk.courses.get(auth, c, {}))) : [])),
            dispatch(sdk.programmes.list(auth, {}))
                .then(programmes => Promise.all(programmes ? programmes.map(p => dispatch(sdk.programmes.get(auth, p, {}))) : [])),
        ])
            .catch(e => notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> }))
            .finally(() => setLoading(false));
    }, []);

    if (loading) return null;
    else return <DashboardLayoutView {...props} programmes={programmes} tutorials={standalones} />
}

function DashboardLayout(props) {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;

    const dispatch = useDispatch();

    const onClick = useCallback((to, ...args) => {
        dispatch(push(`${url}/${to}`, ...args));
    }, []);

    return (
        <DashboardLayoutController {...props} onClick={onClick} />
    )
}

export default DashboardLayout;
