import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';
import { makeStyles } from '@material-ui/styles';
import { CardActions, Button, Grid, CardActionArea, CardContent, Card, Chip } from '@material-ui/core';
import CourseTitle from '../element/course-title';
import CourseDescription from '../element/course-description';
import CourseMedia from '../card/course-card-media';

const useStyles = makeStyles((theme) => ({
    area: {
        position: 'relative',
    },
    button: {
        marginLeft: 'auto',
    },
    description: {
        display: '-webkit-box',
        height: 40,
        '-webkit-line-clamp': 2,
        overflow: 'hidden',
        '-webkit-box-orient': 'vertical',
    },
    chipBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 90%, rgba(0,0,0,0.0) 100%);'
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        padding: theme.spacing(1),
    }
}));

export function CourseCardListItemLoading({ course, ...props }) {
    return (
        <Grid item xs={12} sm={6} md={3} {...props}>
            <Skeleton width='100%' heigth='auto' />
        </Grid>
    )
}

function CourseCardListItemView({ course, programme, ...props }) {

    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} md={3} {...props}>
            <Card elevation={4}>
                <CardActionArea className={classes.area}>
                    <CourseMedia course={course.target} />
                    <CardContent >
                        <CourseTitle noWrap gutterBottom variant="h5" component="h2" course={course.target} />
                        <CourseDescription className={classes.description} variant="body2" color="textSecondary" component="p" course={course.target} />
                    </CardContent>
                    {!programme && /*<div className={classes.chipBar}>*/
                        <Chip className={classes.chip} /*variant='outlined'*/ color='primary' label='Tutorial' />
                    /*</div>*/
                    }
                </CardActionArea>
                <CardActions>
                    <Button className={classes.button} size="small" color="primary">
                        Open
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default function CourseCardListItem({ programme, course: id, onClick, ...props }) {
    return (
        <ResourceGetter resource='courses' id={id} query={{ programme }} render={({ course }) => {
            const isLoading = !(course && course.payload);
            if (isLoading) return <CourseCardListItemLoading {...props} />
            return <CourseCardListItemView {...props} onClick={() => onClick(`${id}`)} programme={programme} course={course} />
        }} />
    );
}
