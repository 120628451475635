import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { Paper, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ContentTitle from '../element/content-title';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

export default function ContentTabs({ content, contents, course, programme, onClick, ...props }) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Tabs
                    value={content}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {contents.map(c =>
                        <Tab {...props} key={c} value={c} onClick={() => onClick(`${c}`)}
                            label={<ContentTitle content={c} course={course} programme={programme} />}
                        />)}
                </Tabs>
            </Paper>
        </React.Fragment>
    );
}
