import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    linkList: {
        listStyle: 'none',
        padding: '0 10px',
        height: '50px'
    },
}));

export default function LinkList({ children, ...props }) {

    const classes = useStyles();

    return (
        <ul {...props} className={classes.linkList}>
            {children}
        </ul>
    );
}
