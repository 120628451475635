import React, { useMemo } from 'react';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { default as reduxThunk } from 'redux-thunk';

import { useHistory } from './history-handler';
import { reducers as sdkReducers } from '../lib/training-sdk';

import applicationReducer from '../app/application/reducer';
import workbenchReducer from '../app/workbench/reducer';

const initialStaticState = {}

///////////////////////////////////////////
// Handler
///////////////////////////////////////////

const StoreHandler = ({ children, initialState = {}, ...props }) => {

    const history = useHistory();

    // Configure the store
    const store = useMemo(() => {

        // Use redux devtools if available
        const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
        // apply middlewares
        const enhancers = composeEnhancers(
            applyMiddleware(
                reduxThunk,
                routerMiddleware(history),
            ));

        // Combine reducers
        const reducers = combineReducers({
            router: connectRouter(history),
            sdk: sdkReducers,
            application: applicationReducer,
            workbench: workbenchReducer,
        });

        // Combine static state with props originated initial state.
        const state = Object.assign(initialStaticState, initialState);

        return createStore(reducers, state, enhancers)
    }, []);

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );

}

export default StoreHandler;