import React, { useEffect, useState } from 'react';
import BreadCrumbsView from '../../common/component/breadcrumbs/breadcrumbs-view';
import { Route, Switch } from 'react-router-dom';
import ContentLayout from '../../content/component/content-layout';
import Loader from '../../common/component/loader';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { Typography } from '@material-ui/core';
import CourseCard from './card/course-card';
import { useSdk } from '../../../lib/training-sdk/context';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { useDispatch } from 'react-redux';
import ActionOk from '../../common/component/notification/action-ok';
import { useNotif } from '../../common/component/notification';

function LoadContents({ programme, course }) {

    const sdk = useSdk();
    const auth = useAuth0();
    const dispatch = useDispatch();
    const notif = useNotif();

    useEffect(() => {
        if (course && course.payload) {
            Promise.all(course.payload.contents.map(content =>
                dispatch(sdk.contents.get(auth, content, { course: course.target, programme }))
            ))
                .catch(e => notif.error(e, { action: (key) => <ActionOk notif={notif} handle={key} /> }));

        }
    }, [programme, course]);

    return null;
}

function GoToFirstContent({ programme, course, id, onClick }) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (course && course.payload) {
            if (course.payload.contents.length > 0) {
                onClick(course.payload.contents[0]);
                console.log('go to first content');
            }
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [course]);

    if (loading) return <Loader message='Loading contents' />
    else return (
        <CourseCard programme={programme} course={id}>
            <CenteredFullScreen>
                <Typography>No contents available</Typography>
            </CenteredFullScreen>
        </CourseCard>
    );
}

function CourseView({ programme, course, id, content, onBack, onClick, url, ...props }) {

    return (
        <React.Fragment>
            <LoadContents programme={programme} course={course} />
            <Switch>
                <Route path={`${url}/:content`} render={() =>
                    <ContentLayout programme={programme} course={id} onBack={onClick} />} />
                <Route render={() =>
                    <GoToFirstContent programme={programme} course={course} id={id} onClick={onClick} />} />
            </Switch>
        </React.Fragment>
    )

}

export default CourseView;
