import React, { useEffect } from 'react';

import Loader from '../../common/component/loader';
import { useDispatch } from 'react-redux';
import { flagApplicationHasStopped } from '../action';

const ApplicationStoppingScreen = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(flagApplicationHasStopped());
    }, []);

    return (
        <Loader message={'Wait until application stops ... '} />
    );
}

export default ApplicationStoppingScreen;
