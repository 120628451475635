import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    AppBar, Avatar, Toolbar, IconButton, Link,
    Menu, MenuItem, Typography
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { push } from 'connected-react-router';
import { useAuth0 } from '../../../lib/auth0-wrapper';
import { makeStyles } from '@material-ui/styles';
import logo from '../../../assets/images/active.logo';
import AppBarLink from './app-bar/link';
import LinkList from './app-bar/link-list';

const useStyles = makeStyles(theme => {console.log(theme); return ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        height: 50,
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[theme.palette.type],
    },
    profile: {
        display: 'inline',
        color: 'inherit',
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'block',
        cursor: 'pointer',
        maxWidth: 120,
        maxHeight: 40,
    },
})});

const Header = (props) => {

    const { title } = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const auth = useAuth0();
    const dispatch = useDispatch();

    const { user, logout } = auth;

    const goHome = useCallback(() => {
        dispatch(push('/app/'));
    }, []);

    const name = useMemo(() => {
        let name = (user && user.user_metadata && user.user_metadata.username) || user.nickname || '';
        return name;
    }, [user]);

    const isMenuOpen = Boolean(anchorEl);

    return (
        <div className={classes.root}>
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar className={classes.toolbar}>

                    <img src={logo} className={classes.title} onClick={goHome} />

                    <LinkList>
                        <AppBarLink href='https://doc.active-framework.com/'>Documentation</AppBarLink>
                        <AppBarLink href='https://download.active-framework.com/'>Downloads</AppBarLink>
                        <AppBarLink href='https://forum.active-framework.com/'>Forum</AppBarLink>
                        <AppBarLink href='https://training.active-framework.com/'>Training</AppBarLink>
                        <AppBarLink href='https://licensing.active-framework.com/'>Licensing</AppBarLink>
                        <AppBarLink href='https://documenter.getpostman.com/view/6251608/Rzn9u2Tm'>API</AppBarLink>
                        <AppBarLink href='https://doc.active-framework.com/category/blog'>Blog</AppBarLink>
                    </LinkList>


                    <div className={classes.grow} />

                    {user && (
                        <div>
                            <Typography
                                className={classes.profile}
                                component='span'>
                                Welcome {name}
                            </Typography>
                            <IconButton
                                aria-owns={'menu-appbar'}
                                aria-haspopup='true'
                                onClick={(evt) => setAnchorEl(evt.currentTarget)}
                                color='inherit'>
                                <Avatar
                                    src={(user && user.picture) || undefined}>
                                    {!(user && user.picture) && <AccountCircle />}
                                </Avatar>
                            </IconButton>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => { setAnchorEl(null); logout(); }}>Logout</MenuItem>
            </Menu>
        </div>
    );
}

export default Header;