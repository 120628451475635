import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';

import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    full: {
        width: '100%',
        display: 'flex',
    }
}));

const breadcrumbsContext = createContext();

export const useBread = () => useContext(breadcrumbsContext);

export default function BreadCrumbsRoot({ children }) {
    const classes = useStyles();

    const [crumbs, setCrumbs] = useState([]);

    const push = (key, opts) =>
        setTimeout(() => {
            setCrumbs(crumbs => {
                crumbs = _.clone(crumbs);
                const crumb = _.find(crumbs, c => c.key === key);
                if (crumb) _.assign(crumb, opts);
                else crumbs.push({ key, ...opts });
                return crumbs;
            });
        }, 0);
        

    const pop = (key) =>
        setCrumbs(crumbs => {
            crumbs = _.clone(crumbs);
            const index = _.findIndex(crumbs, c => c.key === key);
            if(index > -1) crumbs.splice(index, 1);
            return crumbs;
        });

    const reset = (key) =>
        setCrumbs(crumbs => {
            crumbs = _.clone(crumbs);
            const index = _.findIndex(crumbs, c => c.key === key);
            if (index > -1) {
                return crumbs.slice(0, index);
            }
            return crumbs;
        });


    const controller = {
        crumbs,
        push,
        reset,
        pop,
    }

    return (
        <breadcrumbsContext.Provider value={controller}>
            {children}
        </breadcrumbsContext.Provider>
    );
}
