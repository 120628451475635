import React, { useCallback } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import CourseView from './course-view';
import BreadCrumb from '../../common/component/breadcrumbs/breadcrumb';
import { ResourceGetter } from '../../../lib/training-sdk/getter';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import BreadCrumbsView from '../../common/component/breadcrumbs/breadcrumbs-view';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
    },
    content: {
        flexGrow: 1,
        width: '100%',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
}));

function TutorialLayout({ onBack, ...props }) {

    const classes = useStyles();

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    const { tutorial: id } = useParams();

    const dispatch = useDispatch();

    const onClick = useCallback((to, ...args) => {
        dispatch(push(`${url}/${to}`, ...args));
    }, [url]);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <BreadCrumbsView />
                <ResourceGetter resource='courses' id={id} render={({ course }) => (
                    <React.Fragment>
                        <BreadCrumb handle={id} text={course && course.payload && course.payload.title} link={url} />
                        <CourseView id={id} course={course} onBack={onBack} onClick={onClick} url={url} />
                    </React.Fragment>
                )} />
            </div>
        </div >
    )

}

export default TutorialLayout;
