import React, { useEffect } from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

function Title({ setindex, content, ...props }) {

    useEffect(() => {
        if (setindex)
            setindex(content.payload.index);
    }, []);

    return (
        <Typography {...props} >
            {content.payload.title}
        </Typography>
    )
}

function WaitingTitle({ content, ...props }) {
    return (
        <WaitingLoaded data={content} render={() =>
            <Title {...props} content={content} />
        } />
    )
}

function ContentTitle({ content, programme, course, ...props }) {
    return (
        <ResourceGetter resource='contents' id={content} query={{ programme, course }} render={({ content }) =>
            <WaitingLoaded data={content} render={() => <WaitingTitle {...props} content={content} />} />
        } />
    )
}

export default ContentTitle;
