import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        margin: 'auto',
    },
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));


function EmailVerified(props) {

    const { goHome, email } = props;
    const classes = useStyles();

    return (
        <div className={classes.centered}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>Please activate your account before using the platform</Typography>
                    <Typography>We have already sent an email to {email} when you signed up.</Typography>
                    <Typography>Follow the instructions it contains in order to validate your email.</Typography>
                </Grid>
                <Grid className={classes.spaced} item xs={12}>
                    <Button className={classes.button} variant='outlined' onClick={goHome}>Go Home</Button>
                </Grid>
            </Grid>
        </div >
    );

}

export default EmailVerified;