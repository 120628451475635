import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CourseLayout from '../../course/component/course-layout';
import ProgrammeLayout from '../../programme/component/programme-layout';
import BreadCrumbsRoot from '../../common/component/breadcrumbs/breadcrumbs-root';
import { MuiThemeProvider } from '@material-ui/core';
import { themeProgrammes, themeTuto } from '../../../theme';
import DashboardLayout from '../../dashboard/component/dashboard-layout';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import TutorialLayout from '../../course/component/tutorial-layout';
import BreadCrumb from '../../common/component/breadcrumbs/breadcrumb';

const useStyles = makeStyles(theme => ({
    workbench:{
        display: 'block',
    },
    fullWidth: {
        width: '100%',
        position: 'relative',
    },
    toolbar: theme.mixins.toolbar,
}));

const WorkbenchStartedScreen = (props) => {

    const classes = useStyles();

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;

    const dispatch = useDispatch();
    const onClick = useCallback((to, ...args) => {
        dispatch(push(`${url}/${to}`, ...args));
    }, []);

    return (
        <BreadCrumbsRoot>
            <BreadCrumb handle='home' link={url} text={'Home'} />
            <div className={classnames([classes.workbench, classes.fullWidth])}>
                <div className={classes.toolbar} />
                <div className={classes.fullWidth}>
                    <Switch>
                        <Route exact path={`${url}`} render={() => (
                            <DashboardLayout />
                        )} />
                        <Route path={`${url}/programmes/:programme`} render={() => (
                            <MuiThemeProvider theme={themeProgrammes}>
                                <ProgrammeLayout onBack={onClick} />
                            </MuiThemeProvider>
                        )} />
                        <Route path={`${url}/tutorials/:tutorial`} render={() => (
                            <MuiThemeProvider theme={themeTuto}>
                                <TutorialLayout onBack={onClick} />
                            </MuiThemeProvider>
                        )} />
                        <Route render={() => <Redirect to={`${url}`} />} />
                    </Switch>
                </div>
            </div>
        </BreadCrumbsRoot>
    );
}

export default WorkbenchStartedScreen;
