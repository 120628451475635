
import { combineReducers } from 'redux';
import {
    WORKBENCH_STARTING, WORKBENCH_STARTED, WORKBENCH_STOPPING, WORKBENCH_STOPPED,
} from './action';

function status(state = 'stopped', { type, payload, meta }) {
    switch (type) {
        case WORKBENCH_STARTING:
            return 'starting';
        case WORKBENCH_STARTED:
            return 'started';
        case WORKBENCH_STOPPING:
            return 'stopping';
        case WORKBENCH_STOPPED:
            return 'stopped';
        default:
            return state;
    }
}


export default combineReducers({
    status,
});
