
////////////////////////////////////////////////////
//  Action Types
////////////////////////////////////////////////////

export const APPLICATION_STARTING = '@app/STARTS';
export const APPLICATION_STARTED = '@app/STARTED';
export const APPLICATION_STOPPING = '@app/STOPS';
export const APPLICATION_STOPPED = '@app/STOPPED';
export const APPLICATION_ERRED = '@app/ERRED';

export const APPLICATION_RESET_LOADER = '@app/RESET_LOADER';

////////////////////////////////////////////////////
//  Action Creators
////////////////////////////////////////////////////

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function flagApplicationStarts() {
    return {
        type: APPLICATION_STARTING,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagApplicationHasStarted() {
    return {
        type: APPLICATION_STARTED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

/**
 * Used when the workbench is starting. The 'Real' application is running,
 * so we can react to it in the middlewares.
 */
export function flagApplicationStops() {
    return {
        type: APPLICATION_STOPPING,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagApplicationHasStopped() {
    return {
        type: APPLICATION_STOPPED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function flagApplicationIsErred() {
    return {
        type: APPLICATION_ERRED,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}

export function resetLoaderCounter() {
    return {
        type: APPLICATION_RESET_LOADER,
        payload: {},
        meta: { timestamp: Date.now(), }
    }
}