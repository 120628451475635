import React, { useContext, useRef } from 'react';
import { Auth0Provider } from '../lib/auth0-wrapper';
import { useHistory } from './history-handler';

///////////////////////////////////////////
// Context
///////////////////////////////////////////

const authRefContext = React.createContext();
export const useAuthRef = () => useContext(authRefContext);
export const withAuthRef = (Component) => {
    return function withAuthRef(props) {
        return (
            <authRefContext.Consumer>
                {(authRef) => <Component {...props} authRef={authRef} />}
            </authRefContext.Consumer>
        )
    }
};

export const AuthProvider = ({ children, authRef }) => {
    return (
        <authRefContext.Provider value={authRef}>
            {children}
        </authRefContext.Provider>
    );
};

///////////////////////////////////////////
// Handler
///////////////////////////////////////////

const AuthHandler = ({ children }) => {

    const ref = useRef(null);
    const history = useHistory();

    // A function that routes the user to the right place
    // after login
    const onRedirectCallback = appState => {
        if (appState.code === 'valid') {
            history.push(
                appState && appState.targetUrl
                    ? appState.targetUrl
                    : window.location.pathname
            );
        } else if (appState.code === 'invalid') {
            //window.location.replace(process.env.REACT_APP_PUBLIC_URL); // The state wasnt valid anymore, reload is needed
        } else {
            // Do nothing
        }
    };

    return (
        <AuthProvider authRef={ref}>
            <Auth0Provider
                clientRef={ref}
                domain={process.env.REACT_APP_AUTH_DOMAIN}
                client_id={process.env.REACT_APP_AUTH_CLIENT_ID}
                redirect_uri={process.env.REACT_APP_PUBLIC_URL}
                audience={process.env.REACT_APP_API_AUDIENCE}
                onRedirectCallback={onRedirectCallback}
            >
                {children}
            </Auth0Provider>
        </AuthProvider>
    );

}

export default AuthHandler;

///////////////////////////////////////////
// Middleware
///////////////////////////////////////////

/**
 * Use the middleware to provide to every action the 
 * action.lib.auth reference value, so it can be consumed
 * by every other further middleware.
 * @param {any} authRef 
 */
export const authMiddleware = (authRef) => {

    return store => next => action => {
        action.lib = Object.assign({}, action.lib, { auth: authRef.current });
        return next(action);
    };

}
