import { createSelector } from 'reselect';

export function makeApplicationStateSelector() {
    return createSelector(
        applicationStateSelector,
        status => status
    );
}

export const applicationStateSelector = state => state.application.main;

export function makeApplicationLoaderSelector() {
    return createSelector(
        applicationLoaderSelector,
        loader => loader
    );
}

export const applicationLoaderSelector = state => state.application.loader;
