import React, { useMemo } from 'react';

import background from '../../../../assets/images/illustration-b@2x.png';
import { makeStyles } from '@material-ui/core';
import BCardMedia from '../../../common/component/card/card-media';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

const useStyles = makeStyles(theme => ({
    media: {
        minHeight: 180,
    },
}));

function ProgrammeMediaView({ programme, children, ...props }) {

    const classes = useStyles();

    const bg = useMemo(() => (programme && programme.payload && programme.payload.image)
        ? programme.payload.image
        : background,
        [programme]);

    const title = useMemo(() => (programme && programme.payload && programme.payload.title)
        ? programme.payload.title
        : 'programme background image',
        [programme]);

    return (
        <BCardMedia className={classes.media} {...props} background={bg} title={title} >
            {children}
        </BCardMedia>
    )
}

function ProgrammeMedia({ programme, ...props }) {
    return (
        <ResourceGetter resource='programmes' id={programme} render={({ programme }) =>
            <ProgrammeMediaView {...props} programme={programme} />
        } />
    )
}

export default ProgrammeMedia;
