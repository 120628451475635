import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import CenteredFullScreen from '../../common/component/centered-full-screen';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const ServiceLocked = (props) => {

    const { goHome } = props;
    const classes = useStyles();

    return (
        <CenteredFullScreen textAlign='center'>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>The licensing platform access is locked.</Typography>
                    <Typography>Please try again in a few hours, or contact the support.</Typography>
                </Grid>
                <Grid className={classes.spaced} item xs={12}>
                    <Button className={classes.button} variant='outlined' onClick={goHome}>Go Home</Button>
                </Grid>
            </Grid>
        </CenteredFullScreen >
    );

}


export default ServiceLocked;