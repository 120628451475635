import React, { useContext, useMemo } from 'react';
import { createBrowserHistory } from 'history';

///////////////////////////////////////////
// Context
///////////////////////////////////////////

const historyContext = React.createContext();
export const useHistory = () => useContext(historyContext);
export const withHistory = (Component) => {
    return function withHistory(props) {
        return (
            <historyContext.Consumer>
                {(history) => <Component {...props} history={history} />}
            </historyContext.Consumer>
        )
    }
};

export const HistoryProvider = ({ children, history }) => {
    return (
        <historyContext.Provider value={history}>
            {children}
        </historyContext.Provider>
    );
};

///////////////////////////////////////////
// Handler
///////////////////////////////////////////


const HistoryHandler = ({ children }) => {

    const history = useMemo(() => {
        return createBrowserHistory({ basename: process.env.REACT_APP_BASEPATH });
    }, []);

    return (
        <HistoryProvider history={history}>
            {children}
        </HistoryProvider>
    );

}

export default HistoryHandler;