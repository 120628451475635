import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import CourseListItem from './course-list-item';
import { ListItemText, ListItem, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';
import OrderedList from '../../../common/component/ordered-list';
import { useSdk } from '../../../../lib/training-sdk/context';
import { useSelector } from 'react-redux';
import { makeGetCoursesForProgramme } from '../../selector';

function CourseListLoading() {
    return (
        <List dense>
            <ListItem><ListItemText primary={<Skeleton />} /></ListItem>
            <ListItem><ListItemText primary={<Skeleton />} /></ListItem>
            <ListItem><ListItemText primary={<Skeleton />} /></ListItem>
        </List>
    )
}

function CourseListEmpty() {
    return (
        <Typography>No courses available</Typography>
    )
}


function CourseListInternal({ programme, courses, course, ...props }) {
    const sdk = useSdk();
    const selector = useMemo(() => makeGetCoursesForProgramme(sdk), []);
    const ordered = useSelector(state => selector(state, courses));

    return (
        <List>
            {ordered.map(c =>
                <CourseListItem {...props} key={c.target} programme={programme} course={c.target}
                    selected={c.target === course} divider />
            )}
        </List>

    )
}

export default function CourseList({ programme: id, course, ...props }) {
    return (
        <ResourceGetter resource='programmes' id={id} render={({ programme }) => {
            const isLoading = !(programme && programme.payload);
            const courses = (!isLoading && programme.payload.courses) ? programme.payload.courses : [];

            if (isLoading) return <CourseListLoading />;
            if (courses.length === 0) return <CourseListEmpty />;

            return (
                <CourseListInternal {...props} programme={id} course={course} courses={courses} />
            )

        }} />
    );
}
