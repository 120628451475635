import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useBread } from './breadcrumbs-root';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
    },
}));

export function BreadCrumbItem({ crumb, last }) {

    const dispatch = useDispatch();

    const onClick = useCallback((e) => {
        e.preventDefault();
        dispatch(push(crumb.link));
    }, [crumb]);

    const text = crumb.text || crumb.key;

    return crumb.link && !last
        ? (
            <Link color='inherit' href={crumb.link} onClick={onClick}>
                {text}
            </Link>
        ) : (
            <Typography color={last ? 'textPrimary' : 'textSecondary'}>{text}</Typography>
        );

}

export default function BreadCrumbsView() {
    const classes = useStyles();

    const { crumbs } = useBread();

    return (
        <div className={classes.root}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {crumbs.map((crumb, i) => <BreadCrumbItem key={crumb.link} crumb={crumb} last={i == crumbs.length - 1} />)}
            </Breadcrumbs>
        </div >
    );
}
