import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey, purple, orange, lightBlue, lime } from '@material-ui/core/colors';

export const themeProgrammes = createMuiTheme({
    palette: {
        type: 'dark',
        primary: orange,
        secondary: lime,
    },
})

export const themeTuto = createMuiTheme({
    palette: {
        type: 'dark',
        primary: purple,
        secondary: lime,
    },
})

export default createMuiTheme({
    palette: {
        type: 'dark',
        primary: lightBlue,
        secondary: lime,
    },
    drawerWidth: 240,
    mixins: {
        toolbar: {
            minHeight: 50,
        }
    }
});