import React, { useMemo } from 'react';
import ContentTabs from './tabs/content-tabs';
import CourseCard from '../../course/component/card/course-card';
import ContentCarousel from './content-carousel';
import { useSdk } from '../../../lib/training-sdk/context';
import { makeGetContentsForCourse } from '../selector';
import { useSelector } from 'react-redux';
import { ResourceGetter } from '../../../lib/training-sdk/getter';
import _ from 'lodash';

function ContentViewInternal({ programme, course, contents, content, onClick, onBack, ...props }) {
    const sdk = useSdk();
    const selector = useMemo(() => makeGetContentsForCourse(sdk), []);
    const ordered = useSelector(state => selector(state, contents));

    return (
        <CourseCard programme={programme} course={course} tabs={
            <ContentTabs programme={programme} course={course} contents={ordered} content={content} onClick={onBack} />
        }>
            <ContentCarousel programme={programme} course={course} content={content}
                contents={ordered} onClick={onBack} />
        </CourseCard>
    )

}

function ContentView({ programme, course: id, ...props }) {
    return (
        <ResourceGetter resource='courses' id={id} query={{ programme }} render={({ course }) =>
            <ContentViewInternal {...props} course={id} programme={programme}
                contents={course && course.payload ? course.payload.contents : []} />
        } />
    )
}

export default ContentView;
