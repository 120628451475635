import React, { useMemo } from 'react';

import background from '../../../../assets/images/illustration-b@2x.png';
import { makeStyles } from '@material-ui/core';
import BCardMedia from '../../../common/component/card/card-media';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

const useStyles = makeStyles(theme => ({
    media: {
        minHeight: 180,
    },
}));

function CourseMediaView({ course, children, ...props }) {

    const classes = useStyles();

    const bg = useMemo(() => (course && course.payload && course.payload.image)
        ? course.payload.image
        : background,
        [course]);

    const title = useMemo(() => (course && course.payload && course.payload.title)
        ? course.payload.title
        : 'course background image',
        [course]);

    return (
        <BCardMedia className={classes.media} {...props} background={bg} title={title} >
            {children}
        </BCardMedia>
    )
}

function CourseMedia({ course, programme, ...props }) {
    return (
        <ResourceGetter resource='courses' id={course} query={{ programme }} render={({ course }) =>
            <CourseMediaView {...props} course={course} />
        } />
    )
}

export default CourseMedia;
