import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ExpansionPanel, Typography, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: theme.palette.background.default,
    }
}));

function DashboardContainer({ title, children }) {
    const classes = useStyles();

    return (
        <ExpansionPanel className={classes.background} defaultExpanded elevation={8}>
            <ExpansionPanelSummary>
                <Typography component='h2' variant='h4'>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

export default DashboardContainer;
