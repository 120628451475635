import React, { useState, useCallback } from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Document, Outline, Page, pdfjs } from 'react-pdf';
import { makeStyles } from '@material-ui/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
    centered: {
        margin: 'auto',
        width: 'fit-content',
    },
    fullWidth: {
        width: '100%'
    }
}))

function Pdf({ file }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdf, setPdf] = useState(null);

    const classes = useStyles();

    const onDocumentLoadSuccess = useCallback(({ numPages }) => setNumPages(numPages), []);

    return (
        <div className={classes.centered}>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page className={classes.fullWidth} pageNumber={pageNumber} />
                <Outline onItemClick={({ pageNumber }) => setPageNumber(pageNumber)} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
        </div>
    );
}

function PdfViewer(props) {
    const { content } = props;
    return (
        <WaitingLoaded data={content} render={() => <Pdf file={content.payload.pdf} />} />
    )
}

export default PdfViewer;
