import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeApplicationLoaderSelector } from '../selector';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderCounter } from '../action';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '2px',
        zIndex: theme.zIndex.drawer + 2,
        position: 'fixed',
        top: 0,
        left: 0
    },
    linearColorPrimary: {
        backgroundColor: theme.palette.primary[theme.palette.type]
    },
    linearBarColorPrimary: {
        backgroundColor: theme.palette.common.white
    },
}));

function ApplicationLoadingBar() {
    const classes = useStyles();
    const [displayed, setDisplayed] = useState(false);

    const dispatch = useDispatch();

    const applicationLoaderSelector = useMemo(() => makeApplicationLoaderSelector(), []);
    const loader = useSelector(applicationLoaderSelector);

    useEffect(() => {
        if (loader.pending > 0) {

            setDisplayed(true);

            if (loader.pending === loader.erred + loader.finished) {

                const timeout = setTimeout(() => {
                    dispatch(resetLoaderCounter());
                    setDisplayed(false);
                }, 300);

                return () => clearTimeout(timeout);
            }

        }
    }, [loader]);

    const completed = useMemo(() => {
        const { pending, erred, finished } = loader;
        const done = erred + finished
        return (done / pending) * 100;
    }, [loader]);

    if (displayed) {
        return (<LinearProgress
            className={classes.root}
            variant="determinate"
            classes={{
                colorPrimary: classes.linearColorPrimary,
                barColorPrimary: classes.linearBarColorPrimary,
            }}
            value={completed} />)
    } else {
        return null;
    }
}

export default ApplicationLoadingBar;
