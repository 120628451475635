import { createSelector } from 'reselect';
import _ from 'lodash';

export function makeGetContentsForCourse(sdk) {
    return createSelector(
        sdk.contents.selectors.getDb,
        (state, contents) => contents,
        (db, contents) => {
            return _.chain(contents)
                .map(content => db[content])
                .filter(c => c && c.payload)
                .sortBy('payload.index').map('target').value();
        }
    );
}
