import _ from 'lodash';
import { combineReducers } from 'redux';
import Actions from './actions';

const Reducer = (resource) => {

    const actions = new Actions(resource);

    const get = actions['get'];
    const post = actions['post'];
    const put = actions['put'];
    const list = actions['list'];
    const remove = actions['remove'];
    const count = actions['count'];
    const clear = actions['clear'];

    return combineReducers({
        insert: (state = { error: null, status: 'idle', lastUpdate: null }, { type, payload, meta }) => {
            state = _.cloneDeep(state);
            switch (type) {
                case put.pending:
                    state = _.merge(state, { error: null, status: 'put-pending', lastUpdate: meta.timestamp });
                    break;
                case put.success:
                    state = _.merge(state, { error: null, status: 'put-success', lastUpdate: meta.timestamp });
                    break;
                case put.error:
                    state = _.merge(state, { error: payload.error, status: 'put-error', lastUpdate: meta.timestamp });
                    break;
                default:
                    break;
            }
            return state;
        },
        byId: (state = {}, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = {};
                    break;
                case get.pending:
                    state = _.merge(state, { [meta.target]: { target: meta.target, status: 'get-pending', lastUpdate: meta.timestamp } });
                    break;
                case post.pending:
                    state = _.merge(state, { [meta.target]: { status: 'post-pending', lastUpdate: meta.timestamp } });
                    break;
                case remove.pending:
                    state = _.merge(state, { [meta.target]: { status: 'remove-pending', lastUpdate: meta.timestamp } });
                    break;
                case get.success:
                    state = _.merge(state, { [meta.target]: { error: null, status: 'get-success', lastUpdate: meta.timestamp } });
                    state[meta.target].payload = _.clone(payload);
                    break;
                case post.success:
                    state = _.merge(state, { [meta.target]: { error: null, status: 'post-success', lastUpdate: meta.timestamp } });
                    state[meta.target].payload = _.clone(payload);
                    break;
                case put.success:
                    state = _.merge(state, { [payload._id]: { target: payload._id, error: null, status: 'put-success', lastUpdate: meta.timestamp } });
                    state[payload._id].payload = _.clone(payload);
                    break;
                case remove.success:
                    delete state[meta.target];
                    break;
                case get.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'get-error', lastUpdate: meta.timestamp } });
                    break;
                case post.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'post-error', lastUpdate: meta.timestamp } });
                    break;
                case remove.error:
                    state = _.merge(state, { [meta.target]: { error: payload.error, status: 'remove-error', lastUpdate: meta.timestamp } });
                    break;
                default:
                    break;
            }

            return state;
        },
        allIds: (state = { status: 'idle', ids: [], error: null, count: 0 }, { type, payload, meta }) => {

            state = _.cloneDeep(state);

            switch (type) {
                case clear:
                    state = { status: 'idle', ids: [], error: null, count: 0 };
                    break;
                case list.pending:
                    state = _.merge(state, { ids: state.ids, error: null, status: 'list-pending', lastUpdate: meta.timestamp });
                    break;
                case list.success:
                    state = _.merge(state, { error: null, status: 'list-success', lastUpdate: meta.timestamp });
                    state.ids = _.union(state.ids, payload);
                    break;
                case put.success:
                    state = _.merge(state, { count: state.count + 1, error: null, status: 'put-success', lastUpdate: meta.timestamp });
                    state.ids = _.union(state.ids, [payload._id]);
                    break;
                case remove.success:
                    state = _.merge(state, { count: state.count - 1, error: null, status: 'remove-success', lastUpdate: meta.timestamp });
                    state.ids = _.difference(state.ids, [meta.target]);
                    break;
                case list.error:
                    state = _.merge(state, { ids: state.ids, error: payload.error, status: 'list-error', lastUpdate: meta.timestamp });
                    break;
                case count.pending:
                    state = _.merge(state, { status: 'count-pending', lastUpdate: meta.timestamp });
                    break;
                case count.success:
                    state = _.merge(state, { count: payload, status: 'count-success', lastUpdate: meta.timestamp });
                    break;
                case count.error:
                    state = _.merge(state, { status: 'count-error', lastUpdate: meta.timestamp });
                    break;
                default:
                    break;
            }

            return state;
        }
    });
}

export default Reducer;