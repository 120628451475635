import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BCardMediaContent from '../../../common/component/card/card-media-content';
import CourseTitle from '../element/course-title';
import CourseDescription from '../element/course-description';
import ProgrammeMedia from '../../../programme/component/card/programme-card-media';
import CourseMedia from './course-card-media';

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: 0,
    },
    content: {
        width: '60%',
        bottom: 0,
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    description: {
        width: '60%',
    },
}));

function CourseCard({ course, programme, tabs, children }) {
    const classes = useStyles();

    const Media = programme ? ProgrammeMedia : CourseMedia;

    return (
        <Card className={classes.card}>
            <Media style={{ minHeight: 180 }} programme={programme} course={course}>
                <BCardMediaContent className={classes.content}>
                    <CourseTitle course={course} programme={programme} className={classes.title} gutterBottom variant="h4" />
                    <CourseDescription course={course} programme={programme} className={classes.description} color='textSecondary' />
                </BCardMediaContent>
            </Media>
            {tabs}
            <CardContent>
                {children}
            </CardContent>
        </Card >
    )

}

export default CourseCard;
