import { createSelector } from 'reselect';
import _ from 'lodash';

export function makeGetCoursesForProgramme(sdk) {
    return createSelector(
        sdk.courses.selectors.getDb,
        (state, courses) => courses,
        (db, courses) => {
            return _.chain(courses)
                .map(course => db[course])
                .filter(c => c && c.payload)
                .sortBy('payload.index').value();
        }
    );
}

export function makeListCourses(sdk) {
    return createSelector(
        sdk.courses.selectors.getDb,
        (state, programme) => programme,
        (db, programme) => {
            return _.chain(db).values()
                .filter(c => c && c.payload && c.payload.programme === programme)
                .sortBy('payload.index').value();
        }
    );
}


export function makeListStandaloneCourses(sdk) {
    return createSelector(
        sdk.courses.selectors.getDb,
        (db) => {
            return _.chain(db).values()
                .filter(c => c && c.payload && c.payload.programme === null)
                .sortBy('payload.index').value();
        }
    );
}

