import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BCardMediaContent from '../../../common/component/card/card-media-content';
import ProgrammeTitle from '../element/programme-title';
import ProgrammeDescription from '../element/programme-description';
import ProgrammeMedia from './programme-card-media';

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: 0,
    },
    content: {
        width: '60%',
        bottom: 0,
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    description: {
        width: '60%',
    },
}));

function ProgrammeCard({programme, children}) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <ProgrammeMedia programme={programme}>
                <BCardMediaContent className={classes.content}>
                    <ProgrammeTitle programme={programme} className={classes.title} gutterBottom variant="h4" />
                    <ProgrammeDescription programme={programme} className={classes.description} color='textSecondary' />
                </BCardMediaContent>
            </ProgrammeMedia>
        <CardContent>
            {children}
        </CardContent>
        </Card >
    )

}

export default ProgrammeCard;
