
import React, { useEffect, useState } from 'react';
import LoginFailed from './login-failed';
import Loader from '../../common/component/loader';
import { useAuth0 } from '../../../lib/auth0-wrapper';

function RequireLogin(props) {
    const { isAuthenticated, loading, catchError, loginWithRedirect } = useAuth0();
    const { children } = props;

    const [redirecting, setRedirecting] = useState(false);

    const authIsReady = isAuthenticated !== undefined;
    const isLoading = loading || !authIsReady || redirecting;

    // login if logged out.
    useEffect(() => {
        if (authIsReady) {
            if (!isAuthenticated && !catchError) { // if an error was catched, it will be processed after
                setRedirecting(true);
                loginWithRedirect({});
            }
        }
    }, [isAuthenticated]);

    if (isLoading) {
        return <Loader message={'Wait while we log you in ... '} />; // will be redirected to login
    } else {
        if (isAuthenticated) {
            return children;
        } else {
            return <LoginFailed />
        }
    }
}

export default RequireLogin;