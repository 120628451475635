import React from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import CenteredFullScreen from './centered-full-screen';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
    },
}));


const Loader = (props) => {
    const { message } = props;
    const classes = useStyles();

    return (
        <CenteredFullScreen>
            {message && <Typography component='h3'>{message}</Typography>}
            <CircularProgress className={classes.progress} />
        </CenteredFullScreen>
    );
}

export default Loader;
