import React, { useMemo } from 'react';
import _ from 'lodash';

import Skeleton from '@material-ui/lab/Skeleton';

const WaitingLoaded = (props) => {
    const { data, showLoading, render, children, component, variant = 'rect', ...innerProps } = props;

    const normalized = useMemo(() => _.isArray(data) ? data : [data], [data]);

    const oneIsMissing = useMemo(() => _.findIndex(normalized, data => !data || !data.payload), [normalized]);
    const oneIsLoading = useMemo(() => _.findIndex(normalized, data => data && /*data.status &&*/ data.status.endsWith('pending')), [normalized]);

    if (oneIsMissing >= 0) {
        return <Skeleton variant={variant} />
    } else {
        if (showLoading && oneIsLoading >= 0) {
            return <Skeleton variant={variant} />
        } else {
            if (render) {
                return render();
            } else if (component) {
                return React.createElement(component, innerProps);
            } else if (children) {
                return children;
            } else {
                return null;
            }
        }
    }
}

export default WaitingLoaded;
