import React from 'react';
import { Typography } from '@material-ui/core';
import HtmlViewer from '../viewers/html-viewer';
import MarkdownViewer from '../viewers/markdown-viewer';
import PdfViewer from '../viewers/pdf-viewer';
import { Skeleton } from '@material-ui/lab';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

function ContentLoading() {
    return <Skeleton />
}

function NoContent() {
    return <Typography>No content to display.</Typography>
}

function Viewer({ content, ...props }) {

    const isLoading = !(content && content.payload);

    if (isLoading) {
        return <ContentLoading {...props} />
    } else {
        if (content.payload.html || content.payload.raw_html) return <HtmlViewer {...props} content={content} />;
        else if (content.payload.pdf) return <PdfViewer {...props} content={content} />;
        else if (content.payload.markdown) return <MarkdownViewer {...props} content={content} />;
        else return <NoContent {...props} />;
    }
}

function ContentViewer({ programme, course, content, ...props }) {
    return (
        <ResourceGetter resource='contents' id={content} query={{ programme, course }} render={({ content }) => {
            return (
                <Viewer {...props} content={content} />
            )
        }} />
    )
}

export default ContentViewer;
