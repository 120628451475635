import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CenteredFullScreen from '../../common/component/centered-full-screen';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        margin: 'auto',
    },
    spaced: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
}));

function UserBlocked(props) {

    const { goHome } = props;
    const classes = useStyles();

    return (
        <CenteredFullScreen textAlign='center'>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>You account is deactivated</Typography>
                    <Typography>Please contact the support to reactivate your account.</Typography>
                </Grid>
                <Grid className={classes.spaced} item xs={12}>
                    <Button className={classes.button} variant='outlined' onClick={goHome}>Go Home</Button>
                </Grid>
            </Grid>
        </CenteredFullScreen >
    );

}



export default UserBlocked;