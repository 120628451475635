import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';
import { Typography } from '@material-ui/core';
import { ResourceGetter } from '../../../../lib/training-sdk/getter';

function Description({ course, ...props }) {
    return (
        <Typography {...props} >
            {course.payload.description}
        </Typography>
    )
}

function CourseDescription({ course, programme, ...props }) {
    return (
        <ResourceGetter resource='courses' id={course} query={{ programme }} render={({ course }) =>
            <WaitingLoaded data={course} render={() => <Description {...props} course={course} />} />
        } />
    )
}

export default CourseDescription;
