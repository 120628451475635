import React from 'react';
import Workbench from '../../workbench/component';
import NoMatch from '../../error/component/no-match';
import { Switch, Route, Redirect } from 'react-router-dom';

const ApplicationStartedScreen = (props) => {

    return (
        <Switch >
            <Route path='/app' render={() => (
                <Workbench />
            )} />
            <Route exact path='/' render={() => (<Redirect to='/app' />)} />
            <Route /*no match*/ component={NoMatch} />
        </Switch>

    );
}

export default ApplicationStartedScreen;
