import React, { useCallback, useEffect } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import CourseView from './course-view';
import BreadCrumb from '../../common/component/breadcrumbs/breadcrumb';
import { ResourceGetter } from '../../../lib/training-sdk/getter';
import { push } from 'connected-react-router';

function CourseLayout({ programme, onBack, setCurrentCourse, ...props }) {

    let { url } = useRouteMatch();
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    const { course: id } = useParams();

    useEffect(() => {
        setCurrentCourse(id);
    }, [id]);

    const dispatch = useDispatch();

    const onClick = useCallback((to, ...args) => {
        dispatch(push(`${url}/${to}`, ...args));
    }, [url]);

    return (
        <ResourceGetter resource='courses' id={id} query={{ programme }} render={({ course }) => (
            <React.Fragment>
                <BreadCrumb handle={id} text={course && course.payload && course.payload.title} link={url} />
                <CourseView programme={programme} id={id} course={course} onBack={onBack} onClick={onClick} url={url} />
            </React.Fragment>
        )} />
    )

}

export default CourseLayout;
