import React from 'react';
import WaitingLoaded from '../../../common/component/waiting-loaded';

function HtmlViewer(props) {
    const { content } = props;
    return (
        <WaitingLoaded data={content} render={() => (
            <div dangerouslySetInnerHTML={{
                __html: content.payload.html || content.payload.raw_html
            }}></div>
        )} />
    )
}

export default HtmlViewer;
